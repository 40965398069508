import { Box, Tooltip, Typography } from "@mui/material";
import { grey, lightBlue, yellow } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import type { RootState } from "@stores/store";
import FavoriteButton from "components/favoriteButton";
import printy6ImageLoader from "lib/printy6ImageLoader";
import head from "lodash/head";
import Image from "next/image";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { postFevorites } from "services/template";

type Props = {
  size?: "SMALL" | "LARGE";
  template: any;
};

export const Color = styled("div")(({ theme }) => ({
  width: 16,
  height: 16,
  display: "inline-block",
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: 2,
  marginLeft: 2,
  marginRight: 2,
  "&:after": {
    content: "''",
  },
}));

export const Tag = styled("div")(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  borderRadius: 16,
  // paddingLeft: theme.spacing(1),
  // paddingRight: theme.spacing(1),
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  color: "white",
  background: grey[900],
}));

const ImageWrap = ({
  src,
  alt,
  size = 800,
}: {
  src: string;
  alt: string;
  size: number;
}) => {
  return (
    <Box sx={{ position: "relative", bgcolor: "#f2f2f2" }}>
      <Box sx={{ width: "100%", pb: "100%" }}></Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          pb: "100%",
          fontSize: 0,
          "& img": {
            width: "100%",
          },
          "&:after": {
            content: "''",
            width: "100%",
            paddingBottom: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            background: "rgb(0,0,0, 0.02)",
          },
        }}
      >
        {/* @ts-nocheck */}
        {src && (
          <Image
            src={printy6ImageLoader({ src, quality: size })}
            blurDataURL={printy6ImageLoader({ src, quality: 200 })}
            width={size}
            height={size}
            alt={alt}
            unoptimized={true}
            lazyBoundary="200px"
            placeholder="blur"
          />
        )}
      </Box>
    </Box>
  );
};

export default function ProductCard({
  template,
  size = "LARGE",
}: Props): React.ReactElement {
  const favorites = useSelector((store: RootState) => store.app.favorites);
  const dispatch = useDispatch();

  const isFavorite = React.useMemo(() => {
    return favorites?.findIndex((item) => item === template.id) === -1
      ? false
      : true;
  }, [favorites]);

  const [showFavorite, setFavorite] = React.useState(isFavorite);

  // const colors = React.useMemo(() => {
  //   return template.colors || [];
  // }, [template]);

  const sizeStr: string | null = React.useMemo(() => {
    let str = "";

    const sizeOption = template.options?.find((item) => item.name === "Size");

    if (!sizeOption || !sizeOption.values || sizeOption.values.length <= 1) {
      return null;
    }

    // const values: any[] = sortBy(sizeOption.values, "position");

    const colorOption = template.options?.find((item) => item.name === "Color");

    str += `${sizeOption.values.length} sizes`;

    if (
      !(!colorOption || !colorOption.values || colorOption.values.length <= 1)
    ) {
      if (str.length > 0) {
        str += " · ";
      }
      str += `${colorOption.values.length} colors`;
    }

    return str;

    // if (values.length === 1) {
    //   return first(values).value;
    // }

    // return `${first(values).value} - ${last(values).value}`;
  }, [template]);

  const isSmall = React.useMemo(() => {
    return size === "SMALL";
  }, [size]);

  const onFavoriteButtonClick = () => {
    setFavorite(!showFavorite);

    postFevorites({
      template_id: template.id,
    }).then((res) => {
      dispatch({
        type: "APP_INIT",
        data: {
          favorites: res.data.favorites,
        },
      });
    });
  };

  const tagDOM = React.useMemo(() => {
    if (template.collection_tags?.length <= 0) {
      return <></>;
    }
    if (template.collection_tags?.findIndex((item) => item === "New") !== -1) {
      return (
        <Tag
          sx={{
            px: 2,
            bgcolor: yellow[500],
            color: (theme) => theme.palette.text.primary,
          }}
        >
          New
        </Tag>
      );
    }
    if (
      template.collection_tags.findIndex((item) => item === "Bestseller") !== -1
    ) {
      return (
        <Tag
          sx={{
            px: 2,
            bgcolor: lightBlue[800],
          }}
        >
          Bestseller
        </Tag>
      );
    }
    if (
      template.collection_tags.findIndex((item) => item === "Recommend") !== -1
    ) {
      return (
        <Tag
          sx={{
            bgcolor: "primary.main",
          }}
        >
          Recommend
        </Tag>
      );
    }
    return <Tag>{head(template.collection_tags)}</Tag>;
  }, [template.collection_tags]);

  return (
    <Box
      sx={{
        height: "100%",
        cursor: "pointer",
        borderRadius: isSmall ? 1 : 2,
        overflow: "hidden",
        // border: "1px solid",
        // borderColor: (theme) => theme.palette.divider,
        "&:hover": {
          border: "0px",
          boxShadow: (theme) => theme.shadows[6],
          "& .favorite-button": {
            display: "block",
          },
        },
      }}
    >
      <Box
        sx={{
          fontSize: 0,
          position: "relative",
          "&:hover": {
            "& .template-list-style-image": {
              opacity: 1,
              transition: (theme) =>
                theme.transitions.create("opacity", {
                  duration: 500,
                  delay: 50,
                }),
            },
          },
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            // borderRadius: "8px 8px 0 0 ",
            overflow: "hidden",
          }}
        >
          <ImageWrap
            src={template.first_image_src}
            alt={template.title}
            size={isSmall ? 400 : 800}
          />
        </Box>
        {template.secondary_image_src && (
          <Box
            className="template-list-style-image"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2,
              opacity: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              borderRadius: "8px 8px 0 0 ",
              transition: (theme) =>
                theme.transitions.create("opacity", {
                  duration: 500,
                  delay: 0,
                }),
            }}
          >
            <ImageWrap
              src={template.secondary_image_src}
              alt={template.title}
            />
          </Box>
        )}
        {!isSmall && (
          <Tooltip
            title={showFavorite ? "Remove From Favorites" : "Add To Favorites"}
            arrow={true}
            placement="top"
            sx={{
              zIndex: 4,
              cursor: "pointer",
            }}
          >
            <Box
              className="favorite-button"
              sx={{
                position: "absolute",
                display: showFavorite ? "block" : "none",
                left: 12,
                top: 12,
                zIndex: 3,
                // width: 30,
                // height: 30,
              }}
            >
              <FavoriteButton
                isFavorite={isFavorite}
                onClick={onFavoriteButtonClick}
              />
            </Box>
          </Tooltip>
        )}

        {!isSmall && (
          <Box
            sx={{
              position: "absolute",
              right: 12,
              top: 18,
              zIndex: 3,
            }}
          >
            {tagDOM}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          px: {
            xs: 1.5,
            sm: 1.5,
            md: 1.5,
            lg: 2,
            xl: 2,
          },
          pt: 1,
          pb: 2,
        }}
      >
        <Typography
          variant={isSmall ? "body2" : "body1"}
          sx={{
            minHeight: isSmall ? 32 : 48,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            mt: isSmall ? 0 : 0.5,
          }}
        >
          {template.title}
        </Typography>
        {/* <Box
              sx={{
                my: 1,
              }}
            >
              <StarRating
                defaultValue={template?.review?.score || 5}
                readOnly
              />
            </Box> */}
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2" component="div">
            {!isSmall ? "Starting from " : ""}
            {`$${template.cost}`}
          </Typography>
        </Box>
        <Box>
          {/* {colors.length > 0 && (
                <Box sx={{ mt: 1, mx: -0.25 }}>
                  {colors.map((color) => {
                    return (
                      <Color
                        key={color.id}
                        style={{ background: color.color }}
                      />
                    );
                  })}
                </Box>
              )} */}
          {sizeStr && (
            <Box sx={{ mt: 0.5 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="div"
              >
                {sizeStr}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
