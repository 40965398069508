import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
  removeSnackbar,
} from "@stores/actions/notification";
import type { RootState } from "@stores/store";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

let displayed = [];

export const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (store: RootState) => store.app.notifications || []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach((props) => {
      const { key, message, options = {}, dismissed = false } = props;
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey: String) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export const useNotifierAction = () => {
  const dispatch = useDispatch();
  const enqueueSnackbar = (notification) =>
    dispatch(enqueueSnackbarAction(notification));
  const closeSnackbar = (key) => dispatch(closeSnackbarAction(key));

  return { enqueueSnackbar, closeSnackbar };
};
