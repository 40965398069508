import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Necktie(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 1024 1024" {...props}>
      <path d="M488.727273 1024c27.182545 0 256-128.605091 256-158.859636 0-30.254545-178.082909-643.304727-178.082909-643.304727l137.914182-181.527273c0 0 32.162909-40.308364-32.162909-40.308364L307.805091 0C243.432727 0 264.890182 40.308364 264.890182 40.308364l145.92 181.527273C410.810182 221.835636 232.727273 831.301818 232.727273 865.140364 232.727273 898.978909 461.544727 1024 488.727273 1024z"></path>
      <path d="M735.138909 651.636364l48.733091-70.144c9.169455-13.218909 9.914182-35.234909 1.582545-49.058909L605.090909 232.727273 735.138909 651.636364z"></path>
    </SvgIcon>
  );
}
