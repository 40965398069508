import trimStart from "lodash/trimStart";

export default function printy6ImageLoader({
  src,
  quality,
}: {
  src: string;
  quality?: number;
}) {
  const splits = src.split("printy6.com");
  let url = src;
  if (splits.length > 1) {
    url = splits[1];
  } else {
    url = splits[0];
  }
  url = trimStart(url, "/");
  return `https://www.printy6.com/${url}${
    quality ? `?d=${quality}x${quality}` : ""
  }`;
}
