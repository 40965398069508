import ChildCareIcon from "@mui/icons-material/ChildCareRounded";
import KingBedIcon from "@mui/icons-material/KingBedRounded";
import Bag from "components/svg/Bag";
import HighHeeledShoes from "components/svg/HighHeeledShoes";
import Necktie from "components/svg/Necktie";

export const catalogIconMap = {
  "womens-clothing": <HighHeeledShoes fontSize="small" />,
  "mens-clothing": <Necktie fontSize="small" />,
  "kids-and-babies-clothing": <ChildCareIcon fontSize="small" />,
  accessories: <Bag fontSize="small" />,
  "home-and-living": <KingBedIcon fontSize="small" />,
};

export const MENU_NEW_PRODUCTS_KEY = "new-products";
export const MENU_ALL_PRODUCT_KEY = "all";
export const MENU_MY_FAVORITE_KEY = "favorites";
export const MENU_COLLECTION_KEY = "collections";
