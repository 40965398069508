import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Bag(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 280 280" {...props}>
      <path
        d="M245.5,220.8c0,1.7-0.8,3.9-2.3,6.8c-1.5,2.8-3.9,5.5-7.1,8.1c-3.2,2.6-7.1,4.9-11.7,6.9c-4.6,2-10,3-16,3H71.8
	c-6.2,0-11.6-1-16.2-3c-4.6-2-8.5-4.3-11.7-6.9c-3.2-2.6-5.5-5.3-7.1-8.1c-1.5-2.8-2.3-5.1-2.3-6.8l18.6-99.4c0-3.5,1.2-6.5,3.7-8.9
	c2.5-2.4,5.4-3.6,8.8-3.6c1,0.3,2.1,0.6,3.4,1.1c1.1,0.3,2.5,0.6,4,1.1l5.1,1.5V96.1c0-8.4,1.6-16.4,4.9-24
	c3.2-7.5,7.7-14.1,13.3-19.6c5.6-5.6,12.2-10,19.7-13.2c7.5-3.2,15.6-4.9,24.2-4.9s16.6,1.6,24.2,4.9s14.1,7.6,19.7,13.2
	s10.1,12.1,13.3,19.6c3.2,7.5,4.9,15.5,4.9,24v16.5l5.1-1.5c1.5-0.4,2.9-0.8,4-1.1c1.3-0.4,2.4-0.8,3.4-1.1c3.5,0,6.5,1.2,8.9,3.6
	c2.4,2.4,3.6,5.3,3.6,8.9L245.5,220.8z M84.1,152.5c3.5,0,6.5-1.2,8.9-3.6c2.4-2.4,3.6-5.3,3.6-8.9c0-2.4-0.6-4.5-1.7-6.3
	c-1.1-1.8-2.6-3.3-4.4-4.4V140H78v-10.8c-4.1,2.3-6.1,5.8-6.1,10.8c0,3.5,1.2,6.5,3.6,8.9S80.7,152.5,84.1,152.5z M90.4,115.5
	c6.9,1.5,14.6,2.9,23.2,4.1c8.6,1.2,17.4,1.8,26.4,1.8c9,0,17.8-0.6,26.4-1.8c8.6-1.2,16.4-2.6,23.4-4.1V98c0-7-1.3-13.6-3.9-19.8
	c-2.6-6.2-6.2-11.6-10.7-16.2c-4.5-4.6-9.8-8.3-15.8-11s-12.5-4-19.4-4s-13.3,1.3-19.3,4c-6,2.7-11.2,6.3-15.7,11
	c-4.5,4.6-8.1,10.1-10.7,16.2C91.7,84.4,90.4,91,90.4,98C90.4,98,90.4,115.5,90.4,115.5z M195.9,152.5c3.5,0,6.5-1.2,8.9-3.6
	c2.4-2.4,3.6-5.3,3.6-8.9c0-2.4-0.6-4.5-1.7-6.3c-1.1-1.8-2.7-3.3-4.6-4.4V140h-12.2v-10.8c-2,1.1-3.5,2.6-4.6,4.4
	c-1.1,1.8-1.7,3.9-1.7,6.3c0,3.5,1.2,6.5,3.7,8.9C189.6,151.3,192.5,152.5,195.9,152.5L195.9,152.5z"
      />
    </SvgIcon>
  );
}
