import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteIcon from "@mui/icons-material/FavoriteRounded";
import { Box } from "@mui/material";
import clsx from "clsx";
import * as React from "react";

type Props = {
  isFavorite: boolean;
  onClick: () => void;
};

export default function FavoriteButton(props: Props): React.ReactElement {
  const { onClick } = props;
  const [isFavorite, setFavorite] = React.useState(props.isFavorite);

  const handleClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault();

    onClick && onClick();

    if (!isFavorite) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };

  return (
    <Box
      onClick={handleClick}
      className={clsx({
        ["favorite-button-favorite"]: isFavorite,
      })}
      sx={{
        p: 1,
        cursor: "pointer",
        bgcolor: "white",
        borderRadius: "100%",
        boxShadow: (theme) => theme.shadows[1],
        "&:not(.favorite-button-favorite)": {
          "&:hover": {
            boxShadow: (theme) => theme.shadows[3],
            "& .icon-not-favorite": {
              display: "none",
            },
            "& .icon-favorite": {
              color: (theme) => theme.palette.primary.main,
              display: "block",
            },
          },
        },
        "&.favorite-button-favorite": {
          bgcolor: (theme) => theme.palette.primary.main,
          "& .icon-not-favorite": {
            display: "none",
          },
          "& .icon-favorite": {
            display: "block",
            color: "#ffffff",
          },
        },
      }}
    >
      <FavoriteBorderIcon
        className="icon-not-favorite"
        sx={{
          bgcolor: "#ffffff",
          display: "block",
        }}
      />
      <FavoriteIcon
        className="icon-favorite"
        sx={{
          color: "#ffffff",
          display: "none",
        }}
      />
    </Box>
  );
}
