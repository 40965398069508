import fetcher from "lib/fetcher";
import useSWR from "swr";
import useImmutableSWR from "swr/immutable";
import request from "../utils/request";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export function createOrder(param) {
  let response = request({
    url: `${LOCALAPI}/cart/order`,
    method: "post",
    data: param,
  });
  return response;
}

export function getCartLineItemVariant(id, options = {}) {
  let response = request({
    url: `${LOCALAPI}/cart/line_items/${id}/variants`,
    method: "get",
    ...options,
  });

  return response;
}

export function deleteCart(param = {}, options = {}) {
  return fetcher({
    url: `${LOCALAPI}/cart`,
    method: "DELETE",
    data: param,
    ...options,
  }).then((res) => res["cart"]);
}

export function updateCart(param = {}, options = {}) {
  return fetcher({
    url: `${LOCALAPI}/cart`,
    method: "PUT",
    data: param,
    ...options,
  }).then((res) => res["cart"]);
}

export const CART_COUNT_KEY = "/cart/count";

export function getCartCount() {
  return fetcher({
    url: `${LOCALAPI}${CART_COUNT_KEY}`,
  });
}

export function useCartCount() {
  return useSWR<{ count: number }>(CART_COUNT_KEY, getCartCount);
}

export const CART_KEY = "/cart";

export function getCart(options?: any) {
  return fetcher({
    url: `${LOCALAPI}${CART_KEY}`,
    ...(options || {}),
  }).then((res) => res.cart);
}

export type CART = any;

export function useCart() {
  return useImmutableSWR<CART>(CART_KEY, getCart);
}
