import SvgIcon from "@mui/material/SvgIcon";

const CartIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024">
      <path d="M382.68928 286.9248h455.20896c34.9184 0 63.22176 28.99968 63.22176 64.75776 0 5.632-0.7168 11.264-2.12992 16.6912l-24.90368 95.62112c-23.28576 89.45664-95.232 156.8768-184.27904 172.7488a149746.33984 149746.33984 0 0 1-331.71456 59.10528c-17.46944 2.94912-44.17536-7.8848-49.664-29.85984L200.43776 233.49248A37.72416 37.72416 0 0 0 163.84 204.9024a40.96 40.96 0 0 1-40.96-41.00096C122.88 141.25056 141.2096 122.88 163.84 122.88h44.9536a81.92 81.92 0 0 1 77.0048 54.00576l17.408 47.96416a81.92 81.92 0 0 0 79.4624 62.07488zM667.648 552.96a131.072 131.072 0 0 0 131.072-131.072 32.768 32.768 0 1 0-65.536 0 65.536 65.536 0 0 1-65.536 65.536 32.768 32.768 0 1 0 0 65.536zM819.2 901.12a81.92 81.92 0 1 1 0-163.84 81.92 81.92 0 0 1 0 163.84z m-552.96 0a81.92 81.92 0 1 1 0-163.84 81.92 81.92 0 0 1 0 163.84z"></path>
    </SvgIcon>
  );
};

export default CartIcon;
