import fetcher from "lib/fetcher";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export const subscribeBlog = (param: {
  email: string;
  from?: string | null;
}) => {
  return fetcher({
    url: `${LOCALAPI}/blog/subscribe`,
    method: "POST",
    data: param,
  });
};
